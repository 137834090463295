@import url('https://fonts.googleapis.com/css?family=Montserrat:200&display=swap');

* {
  font-family: 'Montserrat';
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.control:active {
  position: relative;
  top: 1px;
}

.animate {
  transition-property: top left;
  transition-duration: 0.2s;
  animation: 0.5s linear 0s infinite alternate flash;
}

@keyframes flash {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }

  60% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
  }
}
